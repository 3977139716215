
.navbar-brand img{
    width: 100px;
    height: 100px;

}

.navbar-brand{
    text-align: center;

}

.navbar-nav{
    text-align: center;
   
}

.navbar-collapse{
justify-content: center;
}