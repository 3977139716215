#about {
  background-color: #da1212;
  padding-bottom: 0px;
}

.container .saas-section-title {
  text-align: left;
  margin-top: 80px;
  margin-bottom: 100px;
}

.container .c {
  margin-right: 0px;
}

.imgquienes img {
  border-radius: 10px 10px 10px 10px;
  box-shadow: 1px 0px 16px 8px rgba(0, 0, 0, 0.58);
}

.container .saas-section-title.quienes-somos h2 {
  text-align: center;
  color: white;
}

.container .saas-section-title.quienes-somos b {
  font-size: 24px;
}

.container .saas-section-title.quienes-somos p {
  color: white;
}

.container .saas-section-title h2 {
  text-align: center;
}

.container .saas-section-title.por-que p b {
  color: #da1212;
}

.container .saas-section-title.por-que {
  text-align: center;
  padding-top: 150px;

  margin-bottom: 100px;
  font-size: 30px;
}

.container .saas-section-title.por-que h6 {
  font-size: 20px;
}

.cont {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 0 30px;
  margin-bottom: 100px;
}
.cont .thumbex {
  margin: 10px 20px 30px;
  width: 100%;
  min-width: 250px;
  max-width: 435px;
  height: 300px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  outline-offset: -15px;

  box-shadow: 0 3px 7px -1px rgb(0, 0, 0);
}
.cont .thumbex .thumbnail {
  overflow: hidden;
  min-width: 250px;
  height: 300px;
  position: relative;
  backface-visibility: hidden;
  transition: all 0.4s ease-out;
}

.cont .thumbex .thumbnail img {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
}
.cont .thumbex .thumbnail span {
  position: absolute;
  z-index: 2;
  top: calc(150px - 20px);
  left: 0;
  right: 0;
  text-align: center;

  color: white;
  font-weight: 300;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.2px;
  transition: all 0.3s ease-out;
}

.cont .thumbex .thumbnail span h3 {
  font-size: 40px;
  color: rgb(0, 0, 0);
  font-family: "Staatliches", cursive;
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
}

.cont .thumbex .thumbnail:hover {
  backface-visibility: hidden;
  transform: scale(1.15, 1.15);
  opacity: 1;
}
.cont .thumbex .thumbnail:hover span {
  opacity: 0;
}

.rec {
  background-color: white;
  width: 100%;
  height: 100vh;
}

.saas-section-title.quienes-somos .bar {
  background-color: white;
}

.saas-section-title.por-que .bar {
  background-color: #da1212;
}
