.main-banner.ban{
    background-image: url(../../images/a.jpg);  
    background-size: cover;
    background-repeat: no-repeat;  
}

.registred{
    font-family: 'monospace';
    font-size: 25px;
}

.ban h1{
    color: rgb(255, 255, 255);
    font-family: 'Staatliches', cursive;
    text-align: left;
}

.ban h2{
    color: rgb(255, 255, 255);
    font-family: 'Staatliches', cursive;
    text-align: left;
    font-size: 25px;
}

.main-banner-content.cont{
    justify-content: left;
    padding: 0px;


}

.row{
    align-items: center;
}

