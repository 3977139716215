#map{
    background-color: #da1212;
    margin-top: 200px;

}

.desc{
  height: 769px;
}

.container .saas-section-title.gmap h2{
    text-align: center;
    color: white;
    font-size: 30px;
    
}

.container .saas-section-title.gmap{

  margin-bottom: 80px;
  
  
}



.insta{
  margin-bottom: 20px;
  margin-top: 20px;

}

.face{
  margin-bottom: 20px;
  margin-top: 0px;

}

.whatsapp{
  margin-bottom: 20px;
  margin-top: 20px;

}

.ubicacion{
  margin-bottom: 20px;
  margin-top: 0px;

}

.imagen{
  width:450px;
  height:100px;
  
}
