.titulo-foto{


    text-align: center;
}

.saas-section-title.collage{
    margin-bottom: 50px;

}

.container .saas-section-title.collage h2{
    text-align: center;
    color: rgb(0, 0, 0);
}

.bar{
    background-color:rgb(255, 0, 0);
    
}

.container .saas-section-title.collage p{
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
 }

 .container .saas-section-title.collage p b{
    color: #da1212;
 }

 .collage .image{
     background-color: aqua;
 }

 .cHQtGv{

    margin: 5px 10px 5px 10px;
 }

 .sc-gtsrHT.eGgOHI{
    border: none;
 }

 .sc-dlnjwi {
    box-shadow:2px 2px 5px 3px rgba(0, 0, 0, 0.4);
  }
  .sc-dlnjwi:hover{
    filter:opacity(5px);
    opacity:1;
    transform: scale(.98);
    box-shadow:none;
  }

  .sc-bdnxRM.jLCvWL{
    width:auto;
  }
