#services{
    background-color: #da1212;
    margin-top: 200px;

}

.row.car{
    margin-left: 0px;
    margin-right: 0px;
}

.saas-section-title.service .bar{
    background-color: white;
    
}

.container .saas-section-title{
    text-align: left;
    margin-top: 80px;
    margin-bottom: 100px;
}

.container .saas-section-title .sub-title{
    text-align: center;
}

.container .saas-section-title h2{
    text-align: center;
}

.container .saas-section-title.service h2{
    text-align: center;
    color: white;
    font-size: 30px;
    
}

.container .saas-section-title.service p{
   color: white;
}

.container .saas-section-title .about-content .blog-card .description p{
    color: rgb(0, 0, 0);
 }

.blog-card {
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    box-shadow: 0 3px 7px -1px rgb(0, 0, 0);
    margin-bottom: 1.6%;
    background: #fff;
    line-height: 1.4;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
}
.blog-card a {
    color: inherit;
}
.blog-card a:hover {
    color: #5ad67d;
}
.blog-card:hover .photo {
    transform: scale(1.3) rotate(3deg);
}
.blog-card .meta {
    position: relative;
    z-index: 0;
    height: 200px;
}
.blog-card .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: transform 0.2s;
}

.blog-card img {
    width: 100%;
    height: 100%;
}



.blog-card .description {
    padding: 1rem;
    background: #fff;
    position: relative;
    z-index: 1;
}

.description p{
    color: black;
}

.blog-card .description h1 {
    line-height: 1;
    margin: 0;
    font-size: 25px;
    color: #da1212;
}
.blog-card .description h2 {
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #a2a2a2;
    margin-top: 5px;
}

.blog-card p {
    position: relative;
    margin: 1rem 0 0;


}
.blog-card p:first-of-type {
    margin-top: 1.25rem;
}
.blog-card p:first-of-type:before {
    content: "";
    position: absolute;
    height: 5px;
    width: 35px;
    top: -0.75rem;
    border-radius: 3px;
}
.blog-card:hover .details {
    left: 0%;
}


@media (min-width: 640px) {
    .blog-card {
        flex-direction: row;
        max-width: 700px;
   }
    .blog-card .meta {
        flex-basis: 40%;
        
   }
    .blog-card .description {
        flex-basis: 60%;
   }
    .blog-card .description:before {
        transform: skewX(-3deg);
        content: "";
        background: #fff;
        width: 30px;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        z-index: -1;
   }
    .blog-card.alt {
        flex-direction: row-reverse;
   }
    .blog-card.alt .description:before {
        left: inherit;
        right: -10px;
        transform: skew(3deg);
   }
    .blog-card.alt .details {
        padding-left: 25px;
   }
}

@media (max-width: 1366px) {
    #services{
        padding-top: 0px;
    }

    .container .saas-section-title.service{

        padding-top: 25px;
    }


    

}