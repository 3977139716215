
#fh5co-main {
  
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
#fh5co-main .fh5co-narrow-content {
  position: relative;
  width: 80%;
  margin: 0 auto;
  padding: 4em 0;
}
.form-control{
     -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  background: transparent;
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
  height: 54px !important;
  font-size: 18px;
  font-weight: 300;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px !important;
}
#message {
  height: 130px !important;
}
.btn.btn-md {
  width: 100%;
}
.btn-primary {
  background: #da1212 !important;
  color: #fff !important;
  border: 2px solid #da1212 !important;
  border-radius: 0px !important;
}

.contacto h1{
    text-align: center;
}

.contacto h3{
    color: rgb(255, 255, 255);
}

.contacto span{
    color: rgb(255, 255, 255);
}

.facebook img{
    width: 70px;
    height: 70px;
}

.btn.btn-outline-link.facebook{
    padding: 0px;
    background-color: rgba(255, 0, 0, 0);
    margin-right: 30px;
}

.btn.btn-outline-link.facebook:focus{

    outline: none;
    box-shadow: none;
 
}


.instagram img{
    width: 50px;
    height: 50px;
}

.btn.btn-outline-link.instagram{
    padding: 0px;
    margin-right: 30px;
    background-color: rgba(255, 0, 0, 0);
}

.btn.btn-outline-link.instagram:focus{

    outline: none;
    box-shadow: none;
 
}

.correo img{
    width: 50px;
    height: 50px;
}

.btn.btn-outline-link.correo{
    padding: 0px;
    background-color: rgba(255, 0, 0, 0);

}

.btn.btn-outline-link.correo:focus{

    outline: none;
    box-shadow: none;
 
}

.redes{
    text-align: center;
}

@media (max-width: 1366px) {
    .contacto{
        margin-top: 140;
    }
    #fh5co-main {
      
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
    }
    #fh5co-main .fh5co-narrow-content {
      position: relative;
      width: 80%;
      margin: 0 auto;
      padding: 4em 0;
    }
    .form-control{
         -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      box-shadow: none;
      background: transparent;
      border: 2px solid rgba(0, 0, 0, 0.1) !important;
      height: 50px !important;
      font-size: 18px;
      font-weight: 300;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      -ms-border-radius: 0px;
      border-radius: 0px !important;
      margin-bottom: 10px;
    }
    #message {
      height: 100px !important;
    }
    .btn.btn-md {
      width: 100%;
    }
    .btn-primary {
      background: #da1212 !important;
      color: #fff !important;
      border: 2px solid #da1212 !important;
      border-radius: 0px !important;
    }
    
    .contacto h1{
        text-align: center;
        padding-top: 60px;
        padding-bottom: 10px;
        font-size: 35px;
    }
    
    .contacto h3{
        color: rgb(255, 255, 255);
        font-size: 15px;
        margin-top: 10px;
    }
    
    .contacto span{
        color: rgb(255, 255, 255);
    }
    
    .facebook img{
        width: 50px;
        height: 50px;
    }
    
    .btn.btn-outline-link.facebook{
        padding: 0px;
        background-color: rgba(255, 0, 0, 0);
        margin-right: 30px;
        padding-bottom: 30px;
    }
    
    .btn.btn-outline-link.facebook:focus{
    
        outline: none;
        box-shadow: none;
     
    }
    
    
    .instagram img{
        
    }
    
    .btn.btn-outline-link.instagram{
        padding: 0px;
        margin-right: 30px;
        background-color: rgba(255, 0, 0, 0);
        padding-bottom: 30px;
    }
    
    .btn.btn-outline-link.instagram:focus{
    
        outline: none;
        box-shadow: none;
     
    }
    
    .correo img{
        
    }
    
    .btn.btn-outline-link.whatsapp{
        padding: 0px;
        background-color: rgba(255, 0, 0, 0);
        padding-bottom: 30px;
    
    }
    
    .btn.btn-outline-link.whatsapp:focus{
    
        outline: none;
        box-shadow: none;
     
    }
    
    .redes{
        text-align: center;
    }
    
}